.discount-bubble {
    border-radius: 50%;
    background-color: #d93717;
    width: 1em;
    height: 1em;

    .discount-label {
        color: #ffffff;
    }
}

.prodpage-discount-bubble {
    border-radius: 50%;
    background-color: #d93717;
    width: 1em;
    height: 1em;

    .prodpage-discount-label {
        color: #ffffff;
    }
}

.recommendations {
    &-content {
        .image-container {
            .discount-bubble {
                .discount-label {
                    color: #ffffff;
                }
            }
        }
    }
}
